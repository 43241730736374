<template>
  <div class=container>
    <div class="row">
      <div class="col-md-12">
        <div class="home-content">
        <h1 class="mt-3">Shortread Assembly Pipeline</h1>
          <div class="text-left">
            <p>The RTDBox short read pipeline includes four stages to construct an RTD using short Illumina RNA-seq reads.</p>
            <h6>Input data: Illumina short RNA-seq reads</h6>
            <p>Currently we only take paired-ended RNA-seq reads. The input files are in fastq/fastq.gz format with one file of read1 and paired with one file of read2 for each biological sample. Users can provide the read and sample information with a metatable.txt of text file. The metatable has three parts:
            <ol>
              <li>(Mandatory) The first line is the genome fasta file.</li>
              <li>(Optional) The second part lists the available transcript annotations to integrated into the final assembled RTD. The annotations must be in “gtf” format and refer to the same reference genome used for the RTD construction. Users can provide one or multiple available annotations line-by-line in the metatable. If annotation is unavailable, please delete this part.</li>
              <li>(Mandatory) The third part lists the information of sample name, fastq file name of read1 and read2. Each line represents one sample (comma delimited).</li>
            </ol>
            </p>
            <p>Example of a metatable:
              <!-- Indentation and layout of below text is replicated on screen, DO NOT CHANGE! -->
              <pre><samp>
    Rubus_idaeus_genome_v1.fa
    Available_transcript_annotation1.gtf
    Available_transcript_annotation2.gtf
    ERR2784286,ERR2784286_1.fastq.gz,ERR2784286_2.fastq.gz
    ERR2784287,ERR2784287_1.fastq.gz,ERR2784287_2.fastq.gz
              </samp></pre>
            </p>
          </div>
          <b-card-group deck>
            <b-card title="Stage 1">
              <b-card-text>Trimming and Quality Control of Short Read FASTQ files.</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('trimming')" variant="primary">Start</b-button>
              </template>
            </b-card>

            <b-card title="Stage 2">
              <b-card-text>Map Short Reads onto Reference Genomes</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('mapping')" variant="primary">Start</b-button>
              </template>
            </b-card>

            <b-card title="Stage 3">
              <b-card-text>Assemble Transcriptome with Stringtie and Scallop</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('assembly')" variant="primary">Start</b-button>
              </template>
            </b-card>

            <b-card title="Stage 4">
              <b-card-text>Assembly merge and quality control with RTDmaker</b-card-text>
              <template v-slot:footer>
                <b-button @click="$router.push('maker')" variant="primary">Start</b-button>
              </template>
            </b-card>
          </b-card-group>
          
          <b-row class="row align-items-center justify-content-center" style="margin: 10px 5px">
            <b-button @click="$router.push('tracking')" variant="primary">Track Exisiting Jobs</b-button>
          </b-row>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'shortread-home'
}
</script>

<style scoped>
</style>
